
<template>
	<el-upload
		ref="elUpload"
		:action="uploadUrl"
		:data='ossParam'
		:limit="limit"
		:list-type="listType"
		:accept="accept"
		:on-success="handleAvatarSuccess"
		:on-remove="handleRemove"
		:on-change="handleChange"
		:before-upload="beforeAvatarUpload"
		:on-exceed="handleExceed"
	>
		<el-button size="small" type="primary">点击上传</el-button>
		<!-- <i class="el-icon-plus"></i> -->
	</el-upload>
</template>
<script>
import {CommonApi} from '@/api';
import {guid} from '@/utils/tools';

const imageTypeList = [
	{value: 'agent', label: '代理商'},
	{value: 'other', label: '其他'},
];
export default {
	name: 'OssUpload',
	props: {
		listType:{
			type: String,
			default: "text",
			validator: function (value) {
				return ['picture-card', 'picture', 'text'].indexOf(value) !== -1
			}
		},
		imageType:{
			type: String,
			default: "other",
			validator: function (value) {
				return imageTypeList.map(item => item.value).indexOf(value) !== -1
			}
		},
		accept: {
			type: String,
		},
		limit: {
			type: Number,
			default: 1
		},
		suffix: {
			type: String,
			default: "*"
		},
		limitSizeMb: {
			type: Number,
			default: 3
		},
		isOneImg:{
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			imageUrl: '',
			ossParam: {},
			ossSign: {},
			uploadUrl: '',
			fileList: [],
			el: null,
		};
	},
	mounted() {
		this.el = this.$refs.elUpload;
	},
	methods: {
		clearFiles(){
			this.el.clearFiles();
		},
		handleAvatarSuccess(res, file, fileList) {
			this.fileList = fileList;
			this.$emit("change", res.data[0].path);
		},
		handleRemove(file, fileList) {
			let fileNames = fileList
				.map(item => item && item.response && item.response.data && item.response.data.filename || '')
				.filter(item => !!item);
			this.$emit("change", '');
		},
		handleChange(file, fileList) {
			let fileNames = fileList
				.map(item => item && item.response && item.response.data && item.response.data.filename || '')
				.filter(item => !!item);
		},
		handleExceed() {
			this.$message.error(this.translate('COMMON.IMAGE_CONTROL_LIMIT', {param: this.limit.toString()}));
		},
		async beforeAvatarUpload(file) {
			const isLt2M = file.size / 1024 / 1024 < this.limitSizeMb;
			const fileSuffix = file.name.split(".").pop().toLowerCase();
			if (this.suffix !== "*") {
				let indexOf = this.suffix.toLowerCase().split(";").indexOf(fileSuffix);
				if (indexOf < 0) {
					this.$message.error(this.translate('COMMON.IMAGE_CONTROL_SUFFIX', {param: this.suffix}));

					return false;
				}
			}
			if (imageTypeList.map(item => item.value).indexOf(this.imageType) < 0) {
				const imageTypeHtml = imageTypeList.map(item => `<li>${item.label} -- ${item.value}</li>`).join("");
				this.$message.error({
					dangerouslyUseHTMLString: true,
					message: `<strong>${translate('COMMON.IMAGE_CONTROL_TYPES')} <ul>${imageTypeHtml}<ul></strong>`
				});
				return false;
			}
			this.uploadUrl = `${process.env.VUE_APP_BASE_API}/aliyun/uploadFile`
			return true;
		}
	}
}
</script>
<style>
.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
.avatar-uploader .el-upload:hover {
	border-color: #409EFF;
}
.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	line-height: 178px;
	text-align: center;
}
.avatar {
	width: 100%;
	height: 100%;
	display: block;
}
</style>
