<template>
    <div>
        <el-dialog :title="title" :visible.sync="open" width="700px" @close="commitOnClose">
            <el-form ref="saveOrEditForm" :model="saveData" style="width:500px" :rules="rules" label-width="160px">
                <el-form-item :label="`请上传需要${type == 'add' ? '新增' : '删除'}文件`" prop="file">
                    <FileUpload @change="getFile" ref="fileUpload"></FileUpload>
                </el-form-item>
            </el-form>
            <el-row style="padding-left:160px">
                <el-button type="primary" @click="submitForm" :loading="loading">提交</el-button>
                <!-- <el-button type="primary" @click="cancel">取消</el-button> -->
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
import FileUpload from '@/components/FileUpload'
import { SpecialApi } from '@/api'
export default {
    components: {
        FileUpload
    },
    props: {
        dicts: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isAdd: {
            type: Boolean,
            default: true,
        },
        type: {
            type: String,
            default: 'add',
        },
        selectRow: {
            type: Object,
            default: function () {
                return {}
            },
        },
        onFresh: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            title: '',
            saveData: {},
            open: false,
            rules: {
                fileUrl: [{ required: true, message: '请上传文件', trigger: 'change' }],
            },
            options: [],
            agentNo: '',
            loading: false,
            rateList: []
        }
    },
    watch: {
        onFresh: function (value) {
            this.open = value;
            if (value) {
                this.resetData();
            }
        }
    },
    methods: {
        cancel() {
            this.saveData = {};
            this.saveData.fileUrl = ''
            this.commitOnClose();
        },
        commitOnClose() {
            this.$emit('on-close');
        },
        async resetData() {
            this.saveData = {};
            this.merchantName = '';
            if (this.$refs.fileUpload) {
                this.$refs.fileUpload.clearFiles()
                this.saveData.fileUrl = ''
            }
        },
        async submitForm() {
            if(!this.saveData.fileUrl){
                this.Message.error('请上传文件')
                return
            }
            let result = ''
            this.type == 'add' ? (result = await SpecialApi.hideDataAgentConfig.batchSave(this.saveData)) : (result = await SpecialApi.hideDataAgentConfig.batchRemoveByFile(this.saveData))
            result.success ? this.Message.success(result.message) : this.Message.error(result.message)
            this.$emit('on-close');

        },
        getFile(file) {
            this.saveData.fileUrl = file
        }
    },

};
</script>

<style scoped></style>
